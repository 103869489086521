import React from 'react';
import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import { createPageUrl, SECTION_HOMEPAGE } from '@wix/communities-blog-client-common';
import { ABOVE_POST_LIST } from '../../../../constants/ooi-slots';
import Page from '../../../common/components/page';
import { useSelector } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination';
import {
  getIsCreatedWithResponsiveEditor,
  getIsFeaturedPostBannerEnabled,
  getLayoutPostSize,
} from '../../../common/selectors/app-settings-selectors';
import { handleNavigation } from '../../../common/services/navigation';
import { isSite as getIsSite } from '../../../common/store/basic-params/basic-params-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { type RouteHomeParams } from '../../constants/routes';
import usePaginationSettings from '../../hoc/use-pagination-settings';
import FeaturedPostBanner from '../featured-post-banner';
import FeedPagePosts from './feed-page-posts';
import styles from './styles.scss';

type Props = {
  params: RouteHomeParams;
  isBlocked?: boolean;
};

export const FeedPage = (props: Props) => {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, props.params.page);
  const isBannerEnabled = useSelector(getIsFeaturedPostBannerEnabled);
  const isSite = useSelector(getIsSite);
  const sectionUrl = useSelector(getSectionUrl);
  const layoutPostSize = useSelector((state) => getLayoutPostSize({ state }));
  const createdWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const hostDimensions = useSelector((_, host) => host.dimensions);
  const stretchedToFullWidth = hostDimensions.width === '';
  const stretchedByHand = hostDimensions.width > layoutPostSize;

  return (
    <Page noSpacing>
      {isBannerEnabled && <FeaturedPostBanner />}
      {createdWithResponsiveEditor ? (
        <SlotsPlaceholder slotId={ABOVE_POST_LIST} />
      ) : (
        <div
          style={{
            width: stretchedToFullWidth
              ? layoutPostSize
              : stretchedByHand
              ? layoutPostSize
              : hostDimensions.width,
            margin: '0 auto',
          }}
        >
          <SlotsPlaceholder slotId={ABOVE_POST_LIST} />
        </div>
      )}
      <div className={styles.container}>
        <FeedPagePosts {...props} />
      </div>
      {pagination.showPagination ? (
        <Pagination
          dataHook="feed-pagination-container"
          page={pagination.page}
          pageSize={pagination.pageSize}
          entityCount={pagination.entityCount}
          createPageUrl={(page) => createPageUrl(page, sectionUrl)}
          handleNavigation={handleNavigation('/', isSite)}
        />
      ) : null}
    </Page>
  );
};

export default FeedPage;
