import './styles.scss';

import React, { useCallback, useEffect, useRef } from 'react';
import {
  feedWidgetStyleParams,
  isLayoutProGallery,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import { useComponents } from '../../../common/components/components-provider/use-components';
import { useActions, useSelector } from '../../../common/components/runtime-context';

import NoPostsFeed from '../../../common/containers/no-posts-feed';
import useLayoutProps from '../../../common/hooks/use-layout-props';
import usePermissions from '../../../common/hooks/use-permissions';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import { getFeedPosts, getFeedPostsByPage } from '../../../common/selectors/post-selectors';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import PostList from '../../components/post-list';
import { type RouteHomeParams } from '../../constants/routes';
import usePaginationSettings from '../../hoc/use-pagination-settings';

type Props = {
  params: RouteHomeParams;
  isBlocked?: boolean;
};

const FeedPagePosts: React.FC<Props> = ({ isBlocked, params }) => {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, params.page);
  const { PostListProGallery } = useComponents();
  const layoutProps = useLayoutProps();
  const slice = useFeedPagePostsSlice(params.page);
  const loaders = useFeedLoader(isBlocked, params.page);

  if (slice.isLoaded && !slice.posts.length) {
    return <NoPostsFeed />;
  }

  if (layoutProps.layoutType && isLayoutProGallery(layoutProps.layoutType)) {
    return (
      <PostListProGallery
        pageStart={pagination.page + 1}
        entityCount={pagination.entityCount}
        loadMore={pagination.showPagination ? undefined : loaders.loadMore}
        allPosts={pagination.showPagination ? slice.currentPagePosts : slice.posts}
        currentPagePosts={slice.currentPagePosts}
        isLoading={slice.isLoading}
        showCreatePostAction={slice.showCreatePostAction}
        canSeeMoreButton={slice.canSeeMoreButton}
        layoutOptions={slice.layoutOptions}
        section={SECTION_HOMEPAGE}
      />
    );
  }

  return (
    <PostList
      pageStart={pagination.page + 1}
      entityCount={pagination.entityCount}
      page={pagination.page}
      pageSize={pagination.pageSize}
      loadRest={pagination.showPagination ? loaders.loadRest : undefined}
      loadMore={pagination.showPagination ? undefined : loaders.loadMore}
      allPosts={pagination.showPagination ? slice.currentPagePosts : slice.posts}
      currentPagePosts={slice.currentPagePosts}
      isLoading={slice.isLoading}
      showCreatePostAction={slice.showCreatePostAction}
    />
  );
};

function useFeedLoader(isBlocked: boolean | undefined, page: string | undefined) {
  const includeContent = useSelector((state) => isPostContentRequired(state));
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, page);
  const prevPageSizeRef = useRef(pagination.pageSize);
  const { fetchFeedPostsWithAdapterPromisified } = useActions();

  const prevIncludeContentRef = useRef(includeContent);

  const fetchFeedPosts = useCallback(
    (pageToFetch = pagination.page) => {
      if (isBlocked) {
        return Promise.resolve();
      }

      return fetchFeedPostsWithAdapterPromisified({
        page: pageToFetch,
        includeContent: prevIncludeContentRef.current,
      });
    },
    [pagination.page, isBlocked, fetchFeedPostsWithAdapterPromisified],
  );

  const loadMore = useCallback(
    (pageToFetch: number) => fetchFeedPosts(pageToFetch),
    [fetchFeedPosts],
  );

  const loadRest = useCallback(
    () => fetchFeedPosts(pagination.page),
    [pagination.page, fetchFeedPosts],
  );

  useEffect(() => {
    if (prevPageSizeRef.current !== pagination.pageSize) {
      prevPageSizeRef.current = pagination.pageSize;
      prevIncludeContentRef.current = includeContent;

      fetchFeedPosts();
    }
  }, [fetchFeedPosts, pagination.pageSize, includeContent]);

  return { loadMore, loadRest, fetchFeedPosts };
}

function useFeedPagePostsSlice(page?: string) {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, page);
  const { canSee } = usePermissions();

  const slice = useSelector((state) => {
    const currentPagePosts = getFeedPostsByPage(state, pagination.page);
    const showCreatePostAction = getIsMobile(state) && isCreatePostButtonVisible(state, canSee);
    const {
      feedSliderShowArrows,
      feedSliderArrowsSize,
      feedSliderAutoSlide,
      feedSliderPauseTime,
      feedSliderArrowsPosition,
      feedSliderArrowsColor,
      feedSliderLoop,
    } = feedWidgetStyleParams;

    return {
      canSeeMoreButton: getCanSeeMoreButton(state, canSee),
      currentPagePosts,
      isLoaded: getIsFeedPostsLoaded(state),
      isLoading: getIsFeedPostsLoading(state),
      posts: getFeedPosts(state),
      showCreatePostAction,
      layoutOptions: {
        showArrows: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderShowArrows.key}`,
          fallback: feedSliderShowArrows.defaultValue,
        }),
        arrowsSize: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsSize.key}`,
          fallback: feedSliderArrowsSize.defaultValue,
        }),
        autoSlide: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderAutoSlide.key}`,
          fallback: feedSliderAutoSlide.defaultValue,
        }),
        pauseTime: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderPauseTime.key}`,
          fallback: feedSliderPauseTime.defaultValue,
        }),
        arrowsPosition: getAppSettingsValue({
          state,
          key: `style.numbers.${feedSliderArrowsPosition.key}`,
          fallback: feedSliderArrowsPosition.defaultValue,
        }),
        arrowsColor: getAppSettingsValue({
          state,
          key: `style.colors.${feedSliderArrowsColor.key}`,
          fallback: feedSliderArrowsColor.defaultValue,
        }),
        loop: getAppSettingsValue({
          state,
          key: `style.booleans.${feedSliderLoop.key}`,
          fallback: feedSliderLoop.defaultValue,
        }),
      },
    };
  });

  return slice;
}

export default FeedPagePosts;
